<template>
  <b-overlay
    variant="transparent"
    :show="isProcessing"
  >
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card>
            <!-- avatar -->
            <b-row
              class="mb-2 pl-0"
            >
              <b-col
                lg="12"
              >
                <avatar-upload
                  :avatar-url="company.avatar"
                  :is-full="true"
                  entity="company"
                  :config="avatarUploadConfig"
                  :disabled="!$store.getters['user/roles']('admin')"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                class="d-block"
              >
                <b-row>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Название"
                      label-for="vi-name"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-name"
                          v-model="model.name"
                          :state="errors.name ? false : null"
                          placeholder="Моя компания"
                          :disabled="userRole.name !== 'admin'"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.name"
                        class="text-danger"
                      >
                        {{ errors.name }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Короткий адрес (поддомен)"
                      label-for="vi-sub"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="model.domain"
                          :state="errors.domain ? false : null"
                          placeholder="Domain"
                          :disabled="userRole.name !== 'admin'"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.domain"
                        class="text-danger"
                      >
                        {{ errors.domain }}
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="model.email"
                          type="email"
                          :state="errors.email ? false : null"
                          placeholder="info@mail.com"
                          :disabled="userRole.name !== 'admin'"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.email"
                        class="text-danger"
                      >
                        {{ errors.email }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <PhoneInput
                      ref="phoneInput"
                      v-model="model.phone"
                      :label="t('auth.field.phone')"
                      :error="errors.phone"
                      :disabled="!$store.getters['user/roles']('admin')"
                    />
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Валюта"
                      label-for="currency"
                    >
                      <v-select
                        id="currency"
                        v-model="selectedCurrency"
                        label="title"
                        :options="currenciesList"
                        :disabled="userRole.name !== 'admin'"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      class="mb-0"
                      append="GMT"
                    >
                      <label>Часовый пояс</label>
                      <b-form-group>
                        <v-select
                          v-model="selectedTimezone"
                          label="title"
                          :options="timezonesList"
                          :disabled="userRole.name !== 'admin'"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Страна"
                      label-for="country"
                    >
                      <v-select
                        id="country"
                        v-model="selectedCountry"
                        label="title"
                        :options="countriesList"
                        :disabled="userRole.name !== 'admin'"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

              <b-row class="mt-2 ml-lg-25">
                  <b-col
                          class="p-0"
                  >
                      <b-form-checkbox
                              v-model="model.show_clients"
                              name="hideFinishedFilter"
                              switch
                              :disabled="userRole.name !== 'admin'"
                      >
                          {{ t('settings.show_clients') }}
                      </b-form-checkbox>
                  </b-col>
              </b-row>

            <b-row class="mt-3 ml-lg-25">
              <b-col
                class="p-0"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  type="submit"
                  :disabled="userRole.name !== 'admin'"
                  @click="onSubmit"
                >
                  {{ t('settings.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <!-- legal user -->
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroup,
  BOverlay,
    BFormCheckbox,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';

import { required, email } from '@validations';

import {
  computed, ref, watch,
} from '@vue/composition-api';
import PhoneInput from '@/components/phoneInput/PhoneInput.vue';
import AvatarUpload from '@/components/avatarUpload/AvatarUpload.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useValidation } from '@/hooks/useValidation';
import {
  emailValidator,
  fieldRequiredValidator,
  minLengthValidator,
} from '@/utils/validation';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BOverlay,
      BFormCheckbox,

    vSelect,
    PhoneInput,
    AvatarUpload,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { successToast, dangerToast } = useToast();

    const companyForm = ref(null);

    const isProcessing = ref(false);

    const userRole = computed(() => store.getters['user/role']);
    const company = computed(() => store.getters['user/account']);

    const model = {
      name: '',
      country: '',
      domain: '',
      email: '',
      phone: '',
      currency: '',
      timezone: '',
      ...[
        'name',
        'country',
        'domain',
        'email',
        'phone',
        'currency',
        'timezone',
        'show_clients',
      ]
        .reduce((acc, val) => (
          company.value[val] ? {
            ...acc,
            [val]: company.value[val],
          } : acc
        ), {}),
    };
    const initialModel = ref(JSON.parse(JSON.stringify(model)));

    const phoneInput = ref(null);

    const minLength = 3;

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model, 'name', t('errors.message.nameRequired')),
      minLengthValidator(model, 'name', minLength, t('errors.message.nameMinLength', {
        length: minLength,
      })),
      fieldRequiredValidator(model, 'domain', t('errors.message.domainRequired')),
      minLengthValidator(model, 'domain', minLength, t('errors.message.domainMinLength', {
        length: minLength,
      })),
      emailValidator(model, 'email', t('errors.message.emailInvalid')),
      fieldRequiredValidator(model, 'email', t('errors.message.emailRequired')),
    ]);

    const getUserData = async () => {
      await store.dispatch('user/getData');

      model.name = company.value.name;
      model.country = company.value.country;
      model.domain = company.value.domain;
      model.email = company.value.email;
      model.phone = company.value.phone;
      model.currency = company.value.currency;
      model.timezone = company.value.timezone;

      initialModel.value = JSON.parse(JSON.stringify(model));
    };
    getUserData();

    // Currency model
    const currenciesList = computed(() => store.getters['user/currencies']);
    const selectedCurrency = ref(
      currenciesList.value.find((currency) => currency.value === company.value.currency),
    );
    watch(selectedCurrency, (val) => { model.currency = val.value; });

    // Timezone model
    const timezonesList = computed(() => store.getters['user/timezones']);
    const selectedTimezone = ref(
      timezonesList.value.find((timezone) => timezone.value === company.value.timezone),
    );
    watch(selectedTimezone, (val) => { model.timezone = val.value; });

    // Country model
    const countriesList = computed(() => store.getters['user/countries']);
    const selectedCountry = ref(
      countriesList.value.find(
        (country) => country.value.toLowerCase() === company.value.country.toLowerCase(),
      ),
    );
    watch(selectedCountry, (val) => { model.country = val.value; });

    const onSubmit = async () => {
      const isFormValid = validate();
      const isPhoneValid = phoneInput.value.validate();
      if (!isFormValid || !isPhoneValid) return;

      isProcessing.value = true;
      const { result } = await store.dispatch('user/updateCompany', model);
      isProcessing.value = false;

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        return;
      }

      dangerToast(
        t('settings.updateFailedMessage.title'),
        t('settings.updateFailedMessage.text'),
      );
    };

    const avatarUploadConfig = {
      updateHandler: (formData) => store.dispatch('user/updateCompanyAvatar', { formData }),
      deleteHandler: (formData) => store.dispatch('user/deleteCompanyAvatar', { formData }),
    };

    return {
      t,
      companyForm,

      userRole,

      model,
      phoneInput,
      errors,
      company,
      isProcessing,

      required,
      email,

      onSubmit,

      currenciesList,
      selectedCurrency,

      timezonesList,
      selectedTimezone,

      countriesList,
      selectedCountry,

      avatarUploadConfig,
    };
  },
};
</script>

<style lang="scss">
.border-danger-inside div {
    border-color: #ea5455 !important;
}

.border-primary-inside:focus div {
    border-color: #7367f0 !important;
}

@media only screen and (max-width: 600px) {
    .media-aside {
        margin: 0 0 10px 33% !important;
    }
}

@import '../../../../@core/scss/vue/libs/vue-select';
</style>
